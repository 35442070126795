module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GarCar","short_name":"GarCar","start_url":"/","background_color":"#34495e","theme_color":"#2c323c","display":"minimal-ui","icon":"src/images/GarCar-Icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"85a90a4bc9bb11d6f651252ccd0929bb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M5B4DZH","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"752123369075368"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
